import './App.css';
import Todo from './components/Todo';
import Form from './components/Form';
import FilterButton from './components/FilterButton';
import React, {useState, useRef, useEffect} from "react";
import { nanoid } from "nanoid";
import LoginButton from './components/auth/LoginButton';
import LogoutButton from './components/auth/LogoutButton';
import Profile from './components/auth/Profile';
import { useAuth0 } from '@auth0/auth0-react';

// Holds the previous state of a passed in value.
// In this case, we are using it to hold the number
// of tasks. Use effect will update on each Render.
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

// Map the filter names against their effect
// on the task.completed property.
const FILTER_MAP = {
  All: () => true,
  Active: (task) => !task.completed,
  Completed: (task) => task.completed
};

// Create an enumerable of the Filter Map object.
const FILTER_NAMES = Object.keys(FILTER_MAP);

function App(props) {
  // Setup the State for both tasks and filter.
  const [tasks, setTasks] = useState(props.tasks);
  const [filter, setFilter] = useState('All');
  const [data, setData] = useState(null);
  const [speciesData, setSpeciesData] = useState(null);
  const [peopleData, setPeopleData] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  // Function for editing the name of a task.
  function editTask(id, newName) {
    const editedTaskList = tasks.map((task) => {
      if (id === task.id) {
        return {...task, name: newName};
      }

      return task;
    });

    setTasks(editedTaskList);
  }

  // Function for toggling the completion status of a task.
  function toggleTaskCompleted(id) {
    const updatedTasks = tasks.map((task) => {
      if (id === task.id) {
        return {...task, completed: !task.completed}
      }

      return task;
    });

    console.log(updatedTasks);
    setTasks(updatedTasks);
  }

  // Function for deleting a task from our list.
  function deleteTask(id) {
    const remainingTasks = tasks.filter((task) => task.id !== id);
    setTasks(remainingTasks);
  }

  // Create our list of tasks. Filter will exclude any tasks
  // that do not meet our defined filter conditions. Map will
  // turn our array into individual components.
  const taskList = tasks
  .filter(FILTER_MAP[filter])
  .map((task) => (
    <Todo
      id={task.id}
      name={task.name}
      completed={task.completed}
      key={task.id}
      toggleTaskCompleted={toggleTaskCompleted}
      deleteTask={deleteTask}
      editTask={editTask}
    />
  ));
  
  // Utilize .map to turn our array of Filter Names into
  // individual Filter Buttons.
  const filterList = FILTER_NAMES.map((name) => (
    <FilterButton
      key={name}
      name={name}
      isPressed={name === filter}
      setFilter={setFilter}
    />
  ));

  // Improve the grammar of the form.
  const tasksNoun = taskList.length !== 1 ? 'tasks' : 'task';
  const headingText = `${taskList.length} ${tasksNoun} remaining`;

  // Function for adding a new task to the end of our list.
  function addTask(name) {
    const newTask = {id: `todo-${nanoid()}`, name: name, completed: false};
    setTasks([...tasks, newTask]);
  }

  const listHeadingRef = useRef(null);
  const prevTaskLength = usePrevious(tasks.length);

  const fetchData = async () => {
    
    await fetch("https://shelhamer-auth0.herokuapp.com")
      .then((res) => res.json())
      .then((data) => setData(data.message));

    await fetch("https://shelhamer-auth0.herokuapp.com/species")
      .then((res) => res.json())
      .then((data) => setSpeciesData(data.species[0].name))
      
    const baseUrl = 'https://shelhamer-auth0.herokuapp.com' || 'http://localhost:8080';
    const token = await getAccessTokenSilently();

    const res = await fetch(`${baseUrl}/people`, {
      headers: {Authorization: `Bearer ${token}` },
    });

    res
      .json()
      .then((json) => {
        setPeopleData(json.people[0].name);
      })
      .catch((err) => console.log(err));
  };

  // Runs after rendering and is used to determine
  // if the number of tasks has decreased. If so,
  // focus on the heading above the list.
  useEffect(() => {
    if (tasks.length - prevTaskLength === -1) {
      listHeadingRef.current.focus();
    }
  }, [tasks.length, prevTaskLength]);

  useEffect(() => {
    fetchData();
  }, [getAccessTokenSilently]);

  return (
    <div className="todoapp stack-large">
      <h1>TodoMatic</h1>
      <h2>{ !data ? "Loading Message..." : data }</h2>
      <h2>{ !speciesData ? "Loading Species..." : speciesData }</h2>
      <h2>{ !peopleData ? "Loading People..." : peopleData }</h2>
      <LoginButton />
      <LogoutButton />
      <Profile />
      <Form addTask={addTask}/> 
      <div className="filters btn-group stack-exception">
        {filterList}
      </div>
      <h2 id="list-heading" tabIndex="-1" ref={listHeadingRef}>
        {headingText}
      </h2>
      <ul
        role="list"
        className="todo-list stack-large stack-exception"
        aria-labelledby="list-heading"
      >
        {taskList}
      </ul>
    </div>
  );
}

export default App;
